.th {
    padding: 0;
}

.control {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
}

.control:hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.icon {
    width: rem(21px);
    height: rem(21px);
    border-radius: rem(21px);
}