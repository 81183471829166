.navbar {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
    height: 100%;
    padding: var(--mantine-spacing-md);
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.links {
    flex: 1;
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
}

.linksInner {
    padding-bottom: var(--mantine-spacing-xl);
}

.footer {
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}
