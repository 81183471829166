.card {
  background-color: var(--mantine-color-blue-filled);
  justify-content: center;
}

.title {
  color: var(--mantine-color-white);
  opacity: 0.65;
}

.stats {
  color: var(--mantine-color-white);
}

.progressSection {
  background-color: var(--mantine-color-white);
}

.progressTrack {
  background-color: var(--mantine-color-blue-3);
}